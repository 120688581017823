import React, { Component } from "react";
import Axios from "axios";
import Constants from "./Constants";
import img4 from "./assets/img/AW_hori.jpg";

import { Fade } from "react-slideshow-image";
// import 'react-slideshow-image/dist/styles.css'
// import HeaderMain from "./HeaderMain";
import HeaderMain from './Navbar';
import Footer from "./Footer";
import CryptoJS from "crypto-js";
import { encKey } from "./utils/passwordGenerator";
// import FooterHome from './FooterHome';

export default class Home extends Component {
  state = {
    slider: [],
    imgPath: "",
    models: [],
    dirPath: "",
  };
  componentDidMount() {
    Axios.get(Constants.getUrl.slider).then((resp) => {
      // console.log(resp);

      this.setState({
        imgPath: resp.data.imagePath,
        slider: resp.data.sliders,
      });
    });
    Axios.get(Constants.getUrl.arModels).then((res) => {
      // console.log(res);
      if (res.data.status === "success") {
        this.setState({
          models: res.data.arModel.data,
          dirPath: res.data.path,
        });
      }
    });
  }

  encrypt = (id) => CryptoJS.AES.encrypt(id, encKey).toString();

  render() {
    let properties = {
      duration: 3000,
      transitionDuration: 1500,
      infinite: true,
      arrows: false,
      pauseOnHover: false,
      indicators: false,
      onChange: (oldIndex, newIndex) => { },
    };
    return (
      <div>
        <HeaderMain />

        <div className="ar">
          <div className="container-fluid">
            <div className="heading">
              <p>Augmented</p>
              <h1>Reality</h1>
            </div>
            <div className="row">
              <div className="col-md-4 col-ms-12">
                <div className="box2">
                  <h2>What is AR?</h2>
                  <p>
                    Augmented reality is a seamless blend of objects that are
                    created in <span>3D</span> and that are already present in{" "}
                    <span> the real world.</span>
                  </p>
                  <p>
                    It creates <span>digital additions</span> or virtual contents and allows you to <span>overlay</span>{" "}
                    these in the <span>physical world,</span> in a way that
                    makes it seem like the content is physically there.
                  </p>
                  <p>
                    In this way, augmented reality alters our{" "}
                    <span> perception of a real-world environment</span> and
                    helps to imagine future realities.
                  </p>
                  <h2>How it works</h2>
                  <p>
                    Imagine shopping for a chair, and using your smartphone to
                    be able to <span>virtually “place”</span> it in your living
                    room, to see exactly what it would look like in real.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="slider slide4"> */}
        </div>
        <div className="container-fluid mb-4">
          <div className="col-md-4 hori-space">
            <div className="para">
              <h2>How we can help you</h2>
              <div className="desc">
                <div className="row">
                  <div className="col-md-1 col-xs-1 col-sm-1">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxNS41NTYgNTE1LjU1NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkPSJtMCAyNzQuMjI2IDE3Ni41NDkgMTc2Ljg4NiAzMzkuMDA3LTMzOC42NzItNDguNjctNDcuOTk3LTI5MC4zMzcgMjkwLTEyOC41NTMtMTI4LjU1MnoiIGZpbGw9IiMxMTg5MDAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiPjwvcGF0aD48L2c+PC9zdmc+"
                      className="augmentImg"
                    />
                  </div>
                  <div className="col-md-11 col-sm-11 pad">
                    <p>
                      Our technology allows <span>you</span> to place 3D digital
                      information into the real world around you.
                    </p>
                  </div>
                </div>
                <div className="col-md-1 col-xs-1 col-sm-1">
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxNS41NTYgNTE1LjU1NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkPSJtMCAyNzQuMjI2IDE3Ni41NDkgMTc2Ljg4NiAzMzkuMDA3LTMzOC42NzItNDguNjctNDcuOTk3LTI5MC4zMzcgMjkwLTEyOC41NTMtMTI4LjU1MnoiIGZpbGw9IiMxMTg5MDAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiPjwvcGF0aD48L2c+PC9zdmc+"
                    className="augmentImg"
                  />
                </div>
                <div className="col-md-11 col-sm-11 pad">
                  <p>
                    Our Augmented Reality technologies for{" "}
                    <span>visual guidance</span> on smartphones, tablets, etc.
                    can help you <span>simplify and improve</span> your sales
                    and marketing.
                  </p>
                </div>
                <div className="col-md-1 col-xs-1 col-sm-1">
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxNS41NTYgNTE1LjU1NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkPSJtMCAyNzQuMjI2IDE3Ni41NDkgMTc2Ljg4NiAzMzkuMDA3LTMzOC42NzItNDguNjctNDcuOTk3LTI5MC4zMzcgMjkwLTEyOC41NTMtMTI4LjU1MnoiIGZpbGw9IiMxMTg5MDAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiPjwvcGF0aD48L2c+PC9zdmc+"
                    className="augmentImg"
                  />
                </div>
                <div className="col-md-11 col-sm-11 pad">
                  <p>
                    We are a research-driven team that focuses on curating ideas
                    and developing communication tools for marketing.
                  </p>
                </div>

                <div className="col-md-1 col-xs-1 col-sm-1">
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxNS41NTYgNTE1LjU1NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBkPSJtMCAyNzQuMjI2IDE3Ni41NDkgMTc2Ljg4NiAzMzkuMDA3LTMzOC42NzItNDguNjctNDcuOTk3LTI5MC4zMzcgMjkwLTEyOC41NTMtMTI4LjU1MnoiIGZpbGw9IiMxMTg5MDAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiPjwvcGF0aD48L2c+PC9zdmc+"
                    className="augmentImg"
                  />
                </div>
                <div className="col-md-11 col-sm-11 pad">
                  <p>
                    We help in promoting your products by launching eye-catching
                    marketing campaigns.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-ms-12 para1">
            <img src={img4} alt="" />
            {/* <iframe src="" frameborder="0"></iframe> */}
          </div>
        </div>
        <div className="container">
          <h1
            style={{
              textAlign: "center",
              color: "#403c3c",
              fontFamily: `"Lato-Medium", sans-serif`,
              fontWeight: "300",
              marginBottom: -8,
              fontSize: "47px",
            }}
          >
            Take a look
          </h1>
        </div>
        {/* <div className="container"> */}
        <div className="heading2">
          <h1>YOUR PRODUCT</h1>
          <h1>IN YOUR SPACE</h1>
        </div>
        {/* </div> */}
        {/* <div className="container"> */}
        <div className="row product">
          {this.state.models.slice(0, 4).map((model, index) => (
            <a key={index} href={"/modalviewer?modelId=" + btoa(model.id)}>
              <div
                // style={{position:"relative",width:"100%",display:"flex"}}
                className={
                  index % 2 === 1
                    ? "col-md-3 col-ms-12 box3"
                    : "col-md-3 col-ms-12 box4"
                }
                style={{
                  borderLeft: index === 0 ? 0 : "10px solid transparent",
                  borderRight: index === 3 ? 0 : "10px solid transparent",
                }}
              >
                <img
                  src={this.state.dirPath + "/" + model.poster_image}

                  alt=""
                  style={{
                    width: "80%",
                    height: "200px",
                    position: "absolute",
                    top: index % 2 === 1 ? "-125px" : "125px",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    // height: "92px",
                  }}
                  className="augmentBox"
                />
                <p className={index % 2 === 1 ? "text-center modTitle" : "title modTitle"}>{model.title}</p>
              </div>

            </a>
          ))}
          {/* <div className="col-md-3 col-ms-12 box4">
            <a href="">
              <img src="" alt="" />
            </a>
          </div>
          <div className="col-md-3 col-ms-12 box3">
            <a href="">
              <img src="" alt="" />
            </a>
          </div>
          <div className="col-md-3 col-ms-12 box4">
            <a href="">
              <img src="" alt="" />
            </a>
          </div> */}
        </div>
        <hr />

        {/* </div> */}

        {/* <div className="slider">
  <div>
    <div className="slider slide4">
    </div>
  </div>
  <div>
    <div className="slider slide1">
    </div>
  </div>
  <div>
    <div className="slider slide2">
    </div>
  </div>
  <div>
    <div className="slider slide3">
    </div>
  </div>
  <div>
    <div className="slider slide5">
    </div>
  </div>
  <div>
    <div className="slider slide6">
    </div>
  </div>
</div> */}

        <Footer />
      </div>
    );
  }
}
