import axios from 'axios'
import React, { Component } from 'react'
import Constants from './Constants'
import renderHTML from 'react-render-html';
import Navbar from './Navbar';
import Footer from './Footer';

export default class About extends Component {
  state = {
    about: []

  }
  componentDidMount() {
    axios.get(Constants.getUrl.about).then(resp => {
      // console.log(resp.data.aboutUs.data)
      this.setState({
        about: resp.data.aboutUs.data[0],
        desc: resp.data.aboutUs.data[0]?.description
      }, () => {
        // console.log(resp.data.aboutUs.data[0]?.title)
      })
    })
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="aboutContent">
          <div className="aboutHead">
            <h1>{this.state.about?.title} </h1>
          </div>
          <div className="aboutText">
            <p>  {renderHTML(this.state.desc ? this.state.desc : "")}
              {/* <br /><br /> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        <br /><br />Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        <br /><br /> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
            </p>
          </div>
        </div>
        <div className="services">
          <div className="servicesHead">
            <h1>Services</h1>
          </div>
          <div className="servicesText">
            <h4>Still Image | Animation | Virtual Walk | Panorama Render | Augmented Reality</h4>
          </div>
        </div>
        <Footer />

      </div>
    )
  }
}
