import React, { Component } from 'react'
// import img1 from "./assets/img/slide1.jpg"
// import img2 from "./assets/img/slide2.jpg"
// import img3 from "./assets/img/slide3.jpg"
// import img4 from "./assets/img/slide4.jpg"
// import img5 from "./assets/img/slide5.jpg"
import logo from "./assets/img/Logo.png"
import axios from 'axios'
import Constants from './Constants'
import Navbar from './Navbar'
import Footer from './Footer'

export default class Work extends Component {
    state = {
        work: []
    }
    componentDidMount() {
        axios.get(Constants.getUrl.virtual_work).then(resp => {
            console.log(resp)
            this.setState({
                work: resp.data.virtualWork.data,
                imgPath: resp.data.videoPath,
            }, () => { console.log(this.state.virtualWork) })
        })
    }
    render() {
        return (
            <div>
                <Navbar />
                <div className="container">
                    <div className="animation">
                        {this.state.work.map(img => (
                            <div className="col-md-4 anm">
                                <video width="100%" controls poster={img.image_thumbnail ? this.state.imgPath + '/' + img.image_thumbnail : logo} controlsList="nodownload">
                                    <source src={this.state.imgPath + '/' + img.video} type="video/mp4" />
                                    <source src={this.state.imgPath + '/' + img.video} type="video/ogg" />
Your browser does not support the video tag.
</video>
                                {/* <iframe title="embed-test" src={this.state.videoPath + '/' + img.video} width="100%"></iframe> */}
                                {/* <img className="galleryImg" src={this.state.imgPath + '/' + img.image} width="100%" alt="" /> */}
                            </div>

                        ))}
                        {/* <img className="galleryImg" src={img1} alt />
  <img className="galleryImg" src={img2} alt />
  <img className="galleryImg" src={img3} alt />
  <img className="galleryImg" src={img4} alt />
  <img className="galleryImg" src={img5} alt />
  <img className="galleryImg" src={img6} alt /> */}
                        {/* <img class="galleryImg" src="https://wikiki.github.io/images/sushi.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/life.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/merry-christmas.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/singer.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/sushi.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/life.jpg" alt=""> */}
                        {/* </div> */}
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}
