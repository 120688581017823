import React, { Component } from 'react'
// import img1 from "./assets/img/slide1.jpg"
// import img2 from "./assets/img/slide2.jpg"
// import img3 from "./assets/img/slide3.jpg"
// import img4 from "./assets/img/slide4.jpg"
// import img5 from "./assets/img/slide5.jpg"
// import img6 from "./assets/img/slide6.jpg"
import axios from 'axios'
import Constants from './Constants'
import Navbar from './Navbar'
import Footer from './Footer'
import Masonry from 'react-masonry-css'

export default class Work extends Component {
    state = {
        work: []
    }
    componentDidMount() {
        axios.get(Constants.getUrl.work).then(resp => {
            console.log(resp)
            this.setState({
                work: resp.data.works.data,
                imgPath: resp.data.imagePath,
            }, () => { console.log(this.state.work) })
        })
    }
    render() {
        const breakpointColumnsObj = {
            default: 3,
            1100: 3,
            700: 2,
            500: 1
        };
        return (
            <div>
                <Navbar />

                <div className="container-fluid still">
                    {/* <div className="gallery "> */}
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {/* array of JSX items */}
                        {this.state.work.map(img => (
                            <img className="" src={this.state.imgPath + '/' + img.image} alt="" width="100%" style={{ marginBottom: '10px' }} />

                        ))}
                    </Masonry>

                    {/* <img className="galleryImg" src={img1} alt />
  <img className="galleryImg" src={img2} alt />
  <img className="galleryImg" src={img3} alt />
  <img className="galleryImg" src={img4} alt />
  <img className="galleryImg" src={img5} alt />
  <img className="galleryImg" src={img6} alt /> */}
                    {/* <img class="galleryImg" src="https://wikiki.github.io/images/sushi.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/life.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/merry-christmas.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/singer.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/sushi.jpg" alt="">
  <img class="galleryImg" src="https://wikiki.github.io/images/life.jpg" alt=""> */}
                    {/* </div> */}
                </div>

                <Footer />
            </div>
        )
    }
}
