import React, { Component } from "react";
import { Link } from "react-router-dom";
import menu from "../src/assets/img/square.png";
import close from "../src/assets/img/cancel.png";
import logo from "../src/assets/img/Logo.png";
export default class HeaderMain extends Component {
  state = {
    showNav: false,
  };
  showNavbar() {
    this.setState({
      showNav: !this.state.showNav,
    });
  }
  close() {
    this.setState({
      showNav: false,
    });
  }
  render() {
    return (
      <div>
        <div className="navbar-main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-7 col-xs-6 logo">
                <a href="/">
                  {" "}
                  <img src={logo} alt="" />
                </a>
                {/* <Link className="navbar-brand" to="/">Kre83d
            <p className="tagLine">Portraying Unbuilt Architecture</p>
                                </Link> */}
              </div>
              <div className="col-sm-5 col-xs-6">
                <img
                  src={menu}
                  onClick={() => {
                    this.showNavbar();
                  }}
                  className="navMenu"
                  width="20%"
                  alt=""
                />
                {this.state.showNav ? (
                  <div className="collapse navbar-collapse  hideNav">
                    <img
                      src={close}
                      onClick={() => {
                        this.close();
                      }}
                      className="closePNG"
                      width="10%"
                      alt=""
                    />
                    <ul className="nav-right">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="menu">
                        <Link>Services</Link>
                        <ul className="drop-down">
                          <li>
                            <Link to="/work">Still Images</Link>
                          </li>
                          <li>
                            <Link to="/animation">Animation</Link>
                          </li>
                          <li>
                            <Link to="/virtual_walk">Virtual Walk</Link>
                          </li>
                          <li>
                            <Link to="/panoroma">Panorama</Link>
                          </li>
                          <li>
                            <Link to="/AugmentedReality">
                              Augmented <br />
                              Reality
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                ) : null}
                <div className="showNav ">
                  <ul className="nav-right">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="menu">
                      <Link>Services</Link>
                      <ul className="drop-down">
                        <li>
                          <Link to="/work">Still Images</Link>
                        </li>
                        <li>
                          <Link to="/animation">Animation</Link>
                        </li>
                        <li>
                          <Link to="/virtual_walk">Virtual Walk</Link>
                        </li>
                        <li>
                          <Link to="/panoroma">Panorama</Link>
                        </li>
                        <li>
                          <Link to="/AugmentedReality">
                            Augmented <br />
                            Reality
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
