import React, { Component } from 'react'
// import img1 from "./assets/img/slide1.jpg"
import img2 from "./assets/img/1888481.jpg"
import img3 from "./assets/img/2.jpg"
import img4 from "./assets/img/3.jpg"
import img5 from "./assets/img/4.jpg"
import img6 from "./assets/img/5.jpg"
import img7 from "./assets/img/6.jpg"
import img8 from "./assets/img/7.jpg"
import img9 from "./assets/img/8.jpg"
import img10 from "./assets/img/9.jpg"
import icon from "./assets/img/circle.svg"
// import img6 from "./assets/img/slide6.jpg"
import axios from 'axios'
import Constants from './Constants'
import Navbar from './Navbar'
import Footer from './Footer'

export default class Work extends Component {
    state = {
        work: []
    }
    componentDidMount() {
        axios.get(Constants.getUrl.work).then(resp => {
            console.log(resp)
            this.setState({
                work: resp.data.works.data,
                imgPath: resp.data.imagePath,
            }, () => { console.log(this.state.work) })
        })
    }
    render() {
        return (
            <div>
                <Navbar />
                <div className="container">
                    <div className="row details">

                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/587490/view/1888481/" target="_blank"><img className="galleryImg1" src={img2} alt /></a>
                            <div className="">
                                <h4>Copia Projects</h4>
                                {/* <p>Copia Projects is a consulting firm specializing in Project Management &amp; BIM, with a vision to stay ahead of time and to deliver quality service. We are proud to bring to you our experience of over 35 years in Project Management consulting. */}
                            </div>
                        </div>
                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/593251/view/1903359/" target="_blank"><img className="galleryImg1" src={img3} alt /></a>
                            <div className="">
                                <h4>SSA</h4>
                                {/* <p>SHISHIR AGRAWAL ARCHITECTS (SAA) is one of the leading architectural firms founded in 2008 With a commitment to making cities better places to live. We believe that architecture and design matter, and that through our work, we can make a positive difference in the world.</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/504995/view/1672591/" target="_blank"><img className="galleryImg1" src={img4} alt /></a>
                            <div className="">
                                <h4>Grid Palette</h4>
                                {/* <p>Grid Palette promises a complete package to the Client from foundation to furnishing of Residential and Commercial spaces . We are a Team of Civil Engineers,Architects,Interior Designers and Visualization Designers. We cover all the Parameters to make a Place what the client always seeks to have.</p> */}
                            </div></div>
                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/547157/view/1797128/" target="_blank"><img className="galleryImg1" src={img5} alt /></a>
                            <div className="">

                                <h4>Triangle Construction</h4>
                                {/* <p>Triangle Constructions is an organisation with a clear focus on developing residential projects in the south part of the capital. We’ve worked and successfully delivered luxury homes in South Delhi.
</p> */}
                            </div>
                        </div>
                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/410152/view/1431442/" target="_blank"><img className="galleryImg1" src={img6} alt /></a>
                            <div className="">
                                <h4>Stiefler+Seiler Architekten</h4>
                                {/* <p>STIEFLER + SEILER ARCHITEKTEN hat bereits mehr als 20 Jahre Erfahrung und Expertise im Planen und Bauen vorzuweisen. Es ist uns nicht nur ein Anliegen, Projekte optimal abzuwickeln, sondern auch professionell und individuell mit den Wünschen und Vorstellungen unserer Kunden umzugehen.</p>*/}
                            </div>
                        </div>
                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/372410/view/1273139/" target="_blank"> <img className="galleryImg1" src={img10} alt /></a>
                            <div className="">
                                <h4>DesignFakt International</h4>
                                {/* <p>Sinalshri Architects and consultants provide high-quality services which satisfy all the parameters to develop a sound design concepts required by the client from last 15 years.
</p> */}
                            </div></div>

                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/365697/view/1245479/" target="_blank"><img className="galleryImg1" src={img8} alt /></a>
                            <div className="">
                                <h4>STEM Spaces Incorporated</h4>
                                {/* <p>STEM Spaces Incorporated is an Architectural Design Firm with its offices in Delhi NCR and Guwahati -Assam. It covers Architecture, Interiors, Structures, Sustainability, Building Engineering and Services, Facade and Fenestration Designs as a complete design package for Retrofitting and Greenfield Projects.</p>*/}
                            </div>
                        </div>

                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/318875/view/1046515/" target="_blank"> <img className="galleryImg1" src={img9} alt /></a>
                            <div className="">
                                <h4>Sinalshri Architects</h4>
                                {/* <p>Sinalshri Architects and consultants provide high-quality services which satisfy all the parameters to develop a sound design concepts required by the client from last 15 years.
</p> */}
                            </div></div>
                        <div className="col-md-4 box">
                            <a href="https://roundme.com/tour/365697/view/1245479/" target="_blank"> <img className="galleryImg1" src={img7} alt /></a>
                            <div className="">
                                <h4>Let’s Build </h4>
                                {/* <p>Let’s Build was incorporated with a concept of closeness, venturing, team work focusing on unique work keeping in mind utmost satisfaction of end user while creating habitat which will last for generations to come.</p> */}
                            </div></div>




                    </div>
                    <div className="content">
                        <div className="row img">
                            <div className="col-md-1" style={{ width: "5px" }}>
                                <img src={icon} alt="" width="10px" /> </div>
                            <div className="col-md-11">
                                <h5>Usually Gyroscope default setting is turned ON. If you do not want to maneuver your mobile device, you may turn the Gyroscope OFF. That way, the device (mobile) may stay still and the images would move itself.</h5>
                            </div></div>
                        <div className="row img">
                            <div className="col-md-1" style={{ width: "5px" }}>
                                <img src={icon} alt="" width="10px" /></div>
                            <div className="col-md-11">
                                <h5>How to turn off Gyroscope on mobile: when u start tour you will see 3 dots on upper right corner, click on that you will find Gyroscope On/Off option.</h5></div></div>
                        <div className="row img">
                            <div className="col-md-1" style={{ width: "5px" }}>
                                <img src={icon} alt="" width="10px" /></div>
                            <div className="col-md-11">
                                <h5>Sound setting on upper left corner: On/Off</h5>
                                <h5>iPhone do not require above settings.</h5></div></div>

                    </div>


                    {/* <img className="galleryImg1" src={img1} alt />
  <img className="galleryImg1" src={img2} alt />
  <img className="galleryImg1" src={img3} alt />
  <img className="galleryImg1" src={img4} alt />
  <img className="galleryImg1" src={img5} alt />
  <img className="galleryImg1" src={img6} alt /> */}
                    {/* <img class="galleryImg1" src="https://wikiki.github.io/images/sushi.jpg" alt="">
  <img class="galleryImg1" src="https://wikiki.github.io/images/life.jpg" alt="">
  <img class="galleryImg1" src="https://wikiki.github.io/images/merry-christmas.jpg" alt="">
  <img class="galleryImg1" src="https://wikiki.github.io/images/singer.jpg" alt="">
  <img class="galleryImg1" src="https://wikiki.github.io/images/sushi.jpg" alt="">
  <img class="galleryImg1" src="https://wikiki.github.io/images/life.jpg" alt=""> */}
                    {/* </div> */}
                </div>

                <Footer />
            </div>
        )
    }
}
