import React, { Component } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Footer extends Component {
  render() {
    return (
      <div className="footer-wrap">
        <div className="footer">
          <div className="social">
            <a href="/"> <i className="fa fa-facebook" /></a>
            <a href="/"> <i className="fa fa-twitter" /></a>
            <a href="/"> <i className="fa fa-linkedin" /></a>
          </div>
          <div className="copyright">
            <p>© 2018 Kre83d. Powered by <a href="https://thespaceelement.com">The <span style={{ color: "red" }}>Space </span> Element</a>. All rights reserved.</p>
          </div>
        </div>

      </div>

    )
  }
}
