import React, { Component } from "react";
import queryString from "query-string";
import "@google/model-viewer";
import CryptoJS from "crypto-js";
import { encKey } from "./utils/passwordGenerator";
import axios from "axios";
import Constants from "./Constants";
import "./assets/css/ar.css";
import arimage from "./assets/img/augmented-reality.svg";
import QRCode from "qrcode.react";
import { getOS } from "./Helpers/os";
// import HeaderMain from './HeaderMain';

const screen = window.screen;

export default class ModalViewer extends Component {
  constructor() {
    super();
    this.state = {
      modelId: queryString.parse(window.location.search).modelId,
      model: {
        id: "",
        title: "",
        poster_image: "",
        glb_file: "",
        usdz_file: "",
        description: "",
        exposure: "",
        shadow_intensity: "",
        ar_placement: "",
        glbFIle: "",
      },
      path: "",
    };
  }
  componentDidMount() {
    this.getModel();
  }

  getModel = () => {
    axios
      .get(Constants.getUrl.model + "/" + atob(this.state.modelId))
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          this.setState(
            {
              model: res.data.arModel,
              path: res.data.url,
              glbFIle: res.data.url + "/" + res.data.arModel.glb_file,
            },
            () => {
              this.modelSetup();
            }
          );
        }
      });
  };
  modelSetup = () => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    document.onkeydown = function (e) {
      if (e.keyCode === 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
        return false;
      }
    };

    const qrtext = document.querySelector("#qrtext");

    // responsive settings

    if (window.innerWidth >= 800) {
      console.log("desktop");
    } else {
      console.log("mobile");
      qrtext.remove();
    }
    const modelViewer = document.querySelector("#reveal");
    modelViewer.environmentImage = "neutral";
    let x = 1,
      y = 1,
      z = 1;
    console.log(modelViewer);
    modelViewer.scale = `${x} ${y} ${z}`;
    // Get the modal
    var modal = document.getElementById("myModal");
    var modalContent = document.querySelector(".modal-content");

    // Get the button that opens the modal
    var btn = document.getElementById("qrtext");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("closeBtn")[0];

    // When the user clicks on the button, open the modal
    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
        modalContent.classList.add("slideIn");
      };
    }

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modalContent.classList.remove("slideIn");
      modal.style.display = "none";
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target === modal) {
        modalContent.classList.remove("slideIn");
        modal.style.display = "none";
      }
    };
  };
  decrypt = () => {
    var bytes = CryptoJS.AES.decrypt(this.state.modelId, encKey);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    console.log(encKey, originalText);
    return originalText;
  };

  render() {
    // <HeaderMain />
    return (
      <div className={"arWrapper"}>
        <model-viewer
          id="reveal"
          src={this.state.glbFIle}
          exposure={this.state.model.exposure}
          ios-src={this.state.path + "/" + this.state.model.usdz_file}
          shadow-intensity={this.state.model.shadow_intensity}
          camera-controls
          autoplay
          ar
          field-of-view="60deg"
          min-field-of-view={screen.width < 600 ? "90deg" : "60deg"}
          max-field-of-view={screen.width < 600 ? "90deg" : "60deg"}
          bounds="tight"
          ar-modes="webxr scene-viewer quick-look"
          ar-placement={this.state.model.ar_placement}
          style={{
            backgroundColor: "#f2f5f7",
            height: "-webkit-fill-available",
            width: "-webkit-fill-available",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow:
              "0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)",
          }}
          loading="lazy"
          // poster={this.state.path + "/" + this.state.model.poster_image}
          environment-image={
            this.state.path + "/" + this.state.model.environment_image
          }
        >
          <button
            slot="ar-button"
            style={{
              backgroundColor: "transparent",
              borderRadius: "20px",
              border: "none",
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)",
              position: "absolute",
              bottom: getOS() === "iOS" ? "60px" : "40px",
              right: "95px",
              // border: "1px solid #222",
              background: "#fff",
              padding: "5px 15px",
            }}
          >
            <div style={{}}>
              <img
                src={arimage}
                width="20px"
                alt=""
                style={{ marginRight: "4px" }}
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  display: "inline-block",
                  margin: "10px 0",
                }}
              >
                View in your space
              </p>
            </div>
          </button>
        </model-viewer>
        {/* {!this.state.loading ? (
          <img
            className="pimg"
            onError={this.fileDoesnotExist}
            src={this.state.path + "/" + this.state.model.poster_image}
            alt=""
          />
        ) : null} */}
        <div id="qrtext">
          <img
            src={arimage}
            width="24px"
            alt=""
            style={{ marginRight: "4px" }}
          />
          <p>View in your space</p>
        </div>
        <div id="myModal" class="modal"></div>
        <div class="modal-content">
          <div class="contentArea">
            <h1>How to View in Augmented Reality</h1>
            <p>
              Scan this QR code with your phone to view the object in your
              space. The experience launches directly from your browser - no app
              required!
            </p>
          </div>
          <div id="qrCodeWrapper">
            <QRCode
              value={window.location.href}
              style={{ height: 200, width: 200, marginTop: 12 }}
            />
          </div>
          <span class="closeBtn">Close</span>
        </div>
      </div>
    );
  }
}
