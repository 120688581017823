import axios from "axios";
import React, { Component } from "react";
import Constants from "./Constants";
import Footer from "./Footer";
import Navbar from "./Navbar";
import phone from "./assets/img/phone-call.png";
import mail from "./assets/img/email.png";

export default class Contact extends Component {
  state = {
    name: "",
    email: "",
    contact_no: "",
    subject: "",
    message: "",
    loading: false,
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  contact = (e) => {
    e.preventDefault();
    const payload = {
      name: this.state.name,
      email: this.state.email,
      contact_no: parseInt(this.state.contact_no),
      subject: this.state.subject,
      message: this.state.message,
    };
    // console.log(payload)
    this.setState({
      loading: true,
    });
    axios.post(Constants.postUrl.contact, payload).then((res) => {
      // console.log(res)
      if (res.data.status === "success") {
        this.setState({
          loading: false,
          msg: true,
          name: "",
          email: "",
          contact_no: "",
          subject: "",
          message: "",
        });
        setTimeout(() => {
          this.setState({
            msg: false,
          });
        }, 2000);
      } else {
        this.setState({
          failed: false,
        });
      }
    });
  };
  render() {
    return (
      <div>
        <Navbar />
        <div className="contactForm">
          <div className="contactHead">
            <h4>
              Feel free to send us any questions you might have, or to leave a
              comment.
            </h4>
          </div>
          <form onSubmit={this.contact} className="inputForm">
            <input
              className="inputGroup"
              type="text"
              name="name"
              placeholder="Name"
              onChange={this.handleInput}
              required
              value={this.state.name}
            />
            <input
              className="inputGroup"
              type="number"
              name="contact_no"
              placeholder="Contact No."
              onChange={this.handleInput}
              required
              value={this.state.contact_no}
            />
            <input
              className="inputGroup"
              type="email"
              name="email"
              placeholder="E-Mail"
              onChange={this.handleInput}
              required
              value={this.state.email}
            />
            <input
              className="inputGroup"
              type="text"
              name="subject"
              placeholder="Subject"
              onChange={this.handleInput}
              required
              value={this.state.subject}
            />
            <textarea
              className="inputGroup"
              style={{ width: "75%" }}
              name="message"
              rows={5}
              placeholder="Message"
              onChange={this.handleInput}
              required={""}
            >
              {this.state.message}
            </textarea>
            <button
              className="inputGroup submitBtn"
              // onClick={() => {
              //   this.contact();
              // }}
            >
              {this.state.loading
                ? "Submitting..."
                : this.state.msg
                ? "Message Sent Successfully "
                : this.state.failed
                ? "Failed !"
                : "Submit"}{" "}
            </button>
            {/* <input className="inputGroup submitBtn" type="submit"onClick={()=>{this.contact()}} name="submit" defaultValue={this.state.loading?"Submitting...":this.state.msg?"Message Sent Successfully ":this.state.failed?"Failed !":"Submit"}  /> */}
          </form>
          <div className="contact">
            <a href="tel:7042333955">
              <img src={phone} alt="" width="30px" />
              +91-7042333955
            </a>
            <a href="mailto:info@kre83d.com">
              <img src={mail} alt="" width="30px" />
              gurudatt@kre83d.com{" "}
            </a>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
