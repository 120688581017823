import React, { Component } from 'react'
import Axios from "axios";
import Constants from './Constants';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import HeaderMain from './HeaderMain';
// import FooterHome from './FooterHome';

export default class Home extends Component {
  state = {
    slider: [],
    imgPath: ""
  }
  componentDidMount() {
    Axios.get(Constants.getUrl.slider).then(resp => {
      // console.log(resp)

      this.setState({
        imgPath: resp.data.imagePath,
        slider: resp.data.sliders
      })
    })
  }

  render() {
    let properties = {
      duration: 3000,
      transitionDuration: 1500,
      infinite: true,
      arrows: false,
      pauseOnHover: false,
      indicators: false,
      onChange: (oldIndex, newIndex) => { }
    };
    return (
      <div>
        <HeaderMain />

        <Fade {...properties}>
          {this.state.slider.map((slider, index) => (
            <div className="slider" style={{ backgroundImage: "url(" + this.state.imgPath + "/" + slider.slider_image + ")" }}>
              {/* <div className="slider slide4"> */}
              <div key={index} >
                {/* <img  src= {this.state.imgPath+'/'+slider.slider_image} width="100%" alt=""/> */}
              </div>
            </div>
            // </div>
          ))}
        </Fade>
        {/* <div className="slider">
  <div>
    <div className="slider slide4">
    </div>
  </div>
  <div>
    <div className="slider slide1">
    </div>
  </div>
  <div>
    <div className="slider slide2">
    </div>
  </div>
  <div>
    <div className="slider slide3">
    </div>
  </div>
  <div>
    <div className="slider slide5">
    </div>
  </div>
  <div>
    <div className="slider slide6">
    </div>
  </div>
</div> */}


      </div>
    )
  }
}
