import React, { Component } from "react";
import "./assets/css/bootstrap.min.css";

import { BrowserRouter, Route } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
// import Footer from './Footer'
// import Header from './Header'
import Home from "./Home";
import Animation from "./Animation";
import Virtual_walk from "./Virtual_walk";
import Panoroma from "./Panoroma";
import Work from "./Work";
import AugmentedReality from "./AugmentedReality";
import "./assets/css/style.css";
import ModalViewer from "./ModalViewer";
// import HeaderMain from './HeaderMain'

export default class App extends Component {
  render() {
    return (
      <div style={{maxWidth:"1500px",margin:"0 auto",position:"relative"}}>
        <BrowserRouter>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/work" component={Work} />
          <Route path="/contact" component={Contact} />
          <Route path="/animation" component={Animation} />
          <Route path="/panoroma" component={Panoroma} />
          <Route path="/virtual_walk" component={Virtual_walk} />
          <Route path="/AugmentedReality" component={AugmentedReality} />
          <Route path="/modalviewer" component={ModalViewer} />
          {/* <Footer/> */}
        </BrowserRouter>
      </div>
    );
  }
}
