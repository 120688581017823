// const mainDomain = "https://admin.eratnd.com/";
const mainDomain = "https://core.kre83d.com/";
// const mainDomain = "http://192.168.0.119:8000/";
const Constants = {
  imgUrl: mainDomain,
  // serverDomain: "https://admin.eratnd.com/",
  serverDomain: "https://core.kre83d.com/",
  getUrl: {
    about: mainDomain + "api/aboutUs?status=active",
    work: mainDomain + "api/works?status=active&perPage=100",
    slider: mainDomain + "api/getSliders?status=active",
    animations: mainDomain + "api/getAllAnimations?status=active",
    virtual_work: mainDomain + "api/getVirtualWork?status=active",
    arModels: mainDomain + "api/getArmodel",
    model: mainDomain + "api/showArmodel",
  },
  postUrl: {
    contact: mainDomain + "api/addContacts",
  },
};
export default Constants;
